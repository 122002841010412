import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';

export const events = {
  CLICK_PLAN: {
    action: 'CLICK_PLAN',
    category: 'SUBSCRIPTION',
  },
  CLICK_CUSTOMER_PORTAL: {
    action: 'CLICK_CUSTOMER_PORTAL',
    category: 'SUBSCRIPTION',
  },
  CLICK_FEATURED_SUBATHON: {
    action: 'CLICK_FEATURED_SUBATHON',
    category: 'FEATURED',
  },
  CLICK_APPBAR_CATEGORY: {
    action: 'CLICK_APPBAR_CATEGORY',
    category: 'APPBAR',
  },
  CLICK_LOGO: {
    action: 'CLICK_LOGO',
    category: 'APPBAR',
  },
  CLICK_OVERLAYS: {
    action: 'CLICK_OVERLAYS',
    category: 'APPBAR',
  },
  CLICK_SUBSCRIPTION: {
    action: 'CLICK_SUBSCRIPTION',
    category: 'APPBAR',
  },
  CLICK_REVENUE: {
    action: 'CLICK_REVENUE',
    category: 'APPBAR',
  },
  SEARCH_TEMPLATE: {
    action: 'SEARCH_TEMPLATE',
    category: 'APPBAR',
  },
  CLICK_OCOINS: {
    action: 'CLICK_OCOINS',
    category: 'APPBAR',
  },
  CLICK_CARD_LINK: {
    action: 'CLICK_CARD_LINK',
    category: 'TEMPLATE_DIALOG',
  },
  CLICK_DIALOG_SUBSCRIPTION: {
    action: 'CLICK_DIALOG_SUBSCRIPTION',
    category: 'TEMPLATE_DIALOG',
  },
  CLICK_DIALOG_LINK: {
    action: 'CLICK_DIALOG_LINK',
    category: 'TEMPLATE_DIALOG',
  },
  CLICK_NOTIFICATION_ICON: {
    action: 'CLICK_NOTIFICATION_ICON',
    category: 'APPBAR',
  },
  CLICK_USER: {
    action: 'CLICK_USER',
    category: 'APPBAR',
  },
  CLICK_CHANNEL: {
    action: 'CLICK_CHANNEL',
    category: 'APPBAR',
  },
  CLICK_PAYOUT_SETTINGS: {
    action: 'CLICK_PAYOUT_SETTINGS',
    category: 'APPBAR',
  },
  CLICK_SETTINGS: {
    action: 'CLICK_SETTINGS',
    category: 'APPBAR',
  },
  CLICK_WALLET: {
    action: 'CLICK_WALLET',
    category: 'APPBAR',
  },
  CLICK_LOGIN: {
    action: 'CLICK_LOGIN',
    category: 'APPBAR',
  },
  CLICK_SIGNUP: {
    action: 'CLICK_SIGNUP',
    category: 'APPBAR',
  },
  CLICK_DASHBOARD_MY_OVERLAY: {
    action: 'CLICK_DASHBOARD_MY_OVERLAY',
    category: 'DASHBOARD',
  },
  CLICK_DASHBOARD_TEMPLATE: {
    action: 'CLICK_DASHBOARD_TEMPLATE',
    category: 'DASHBOARD',
  },
  CLICK_DASHBOARD_CATEGORY: {
    action: 'CLICK_DASHBOARD_CATEGORY',
    category: 'DASHBOARD',
  },
  CLICK_SEE_MORE_OVERLAYS: {
    action: 'CLICK_SEE_MORE_OVERLAYS',
    category: 'DASHBOARD',
  },
  CLICK_SEE_MY_OVERLAYS: {
    action: 'CLICK_SEE_MY_OVERLAYS',
    category: 'DASHBOARD',
  },
  CLICK_SEE_MY_WALLET: {
    action: 'CLICK_SEE_MY_WALLET',
    category: 'DASHBOARD',
  },
  CLICK_EARNED_REFERRAL: {
    action: 'CLICK_EARNED_REFERRAL',
    category: 'DASHBOARD',
  },
  CLICK_EARNED_SUBATHON: {
    action: 'CLICK_EARNED_SUBATHON',
    category: 'DASHBOARD',
  },
  CLICK_NOTIFICATION: {
    action: 'CLICK_NOTIFICATION',
    category: 'NOTIFICATIONS',
  },
  CLICK_DELETE_OVERLAY: {
    action: 'CLICK_DELETE_OVERLAY',
    category: 'CARD',
  },
  OPEN_CARD_MENU: {
    action: 'OPEN_CARD_MENU',
    category: 'CARD',
  },
  CLICK_MY_OVERLAY: {
    action: 'CLICK_MY_OVERLAY',
    category: 'MY_OVERLAYS',
  },
  CHANGE_AVATAR: {
    action: 'CHANGE_AVATAR',
    category: 'SETTINGS',
  },
  CHANGE_BANNER: {
    action: 'CHANGE_BANNER',
    category: 'CHANNEL',
  },
  CHANGE_SOCIAL_MEDIA: {
    action: 'CHANGE_SOCIAL_MEDIA',
    category: 'CHANNEL',
  },
  CHANGE_USER_DATA: {
    action: 'CHANGE_USER_DATA',
    category: 'SETTINGS',
  },
  CLICK_INTEGRATE_TWITCH: {
    action: 'CLICK_INTEGRATE_TWITCH',
    category: 'SETTINGS',
  },
  CREATE_API_KEY: {
    action: 'CREATE_API_KEY',
    category: 'SETTINGS',
  },
  DELETE_API_KEY: {
    action: 'DELETE_API_KEY',
    category: 'SETTINGS',
  },
  LOGOUT: {
    action: 'LOGOUT',
    category: 'SETTINGS',
  },
  SIGNIN: {
    action: 'SIGNIN',
    category: 'SETTINGS',
  },
  SIGNUP: {
    action: 'SIGNUP',
    category: 'SETTINGS',
  },
  CLICK_TEMPLATE: {
    action: 'CLICK_TEMPLATE',
    category: 'TEMPLATES',
  },
  CREATE_OVERLAY: {
    action: 'CREATE_OVERLAY',
    category: 'TEMPLATES',
  },
  UPDATE_OVERLAY: {
    action: 'UPDATE_OVERLAY',
    category: 'TEMPLATES',
  },
  TRACK_OVERLAY_TIME: {
    action: 'TRACK_OVERLAY_TIME',
    category: 'OVERLAY',
  },
  CLICK_SHARE_MENU: {
    action: 'CLICK_SHARE_MENU',
    category: 'BUILDER',
  },
  CLICK_COPY_URL_MENU: {
    action: 'CLICK_COPY_URL_MENU',
    category: 'BUILDER',
  },
  CLICK_SETTINGS_MENU: {
    action: 'CLICK_SETTINGS_MENU',
    category: 'BUILDER',
  },
  CLICK_STREAMDECK_MENU: {
    action: 'CLICK_STREAMDECK_MENU',
    category: 'BUILDER',
  },
  SAVE_BUILDER_SETTINGS: {
    action: 'SAVE_BUILDER_SETTINGS',
    category: 'BUILDER',
  },
  SHOW_STREAM_DECK_MESSAGE: {
    action: 'SHOW_STREAM_DECK_MESSAGE',
    category: 'BUILDER',
  },
  CLICK_COPY_URL_SHARE_DIALOG: {
    action: 'CLICK_COPY_URL_SHARE_DIALOG',
    category: 'SHARE_DIALOG',
  },
  CHANGE_BILLING_DATA: {
    action: 'CHANGE_BILLING_DATA',
    category: 'WALLET',
  },
  CREATE_WITHDRAW: {
    action: 'CREATE_WITHDRAW',
    category: 'WALLET',
  },
  CLICK_STREAMDECK_BUTTON: {
    action: 'CLICK_STREAMDECK_BUTTON',
    category: 'STREAMDECK',
  },
  CLICK_PAY_SUBATHON: {
    action: 'CLICK_PAY_SUBATHON',
    category: 'SUBATHON',
  },
  CLICK_MARATHON: {
    action: 'CLICK_MARATHON',
    category: 'REVENUE',
  },
  COPY_REFERRAL: {
    action: 'COPY_REFERRAL',
    category: 'REVENUE',
  },
  CLICK_LOGIN_REVENUE: {
    action: 'CLICK_LOGIN',
    category: 'REVENUE',
  },
  SHOW_PREMIUM_DIALOG: {
    action: 'SHOW_PREMIUM_DIALOG',
    category: 'BUILDER',
  },
  CLICK_OCOINS_BUY_NOW: {
    action: 'CLICK_OCOINS_BUY_NOW',
    category: 'OCOINS',
  },
};

export function initialize() {
  if (process.env.NODE_ENV === 'development') return;

  ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
  mixpanel.init(process.env.REACT_APP_MIXPANEL);
}

export function setUserId(userId, data) {
  if (process.env.NODE_ENV === 'development') return;

  ReactGA.set({ userId });
  mixpanel.identify(userId);
  mixpanel.people.set({
    name: data.name,
    surname: data.surname,
    username: data.username,
    $email: data.email,
    plan: data.premium ? 'Premium' : 'Free',
  });
}

export function hit(route) {
  if (process.env.NODE_ENV === 'development') return;
  ReactGA.pageview(route);
  mixpanel.track(route);
}

export function event(eventName, value = {}) {
  if (process.env.NODE_ENV === 'development') return;

  const { action, category } = events[eventName] || {};
  ReactGA.event({ category, action, ...value });
  mixpanel.track(action, { category, ...value });
}

const analytics = {
  event,
  events,
  hit,
  initialize,
  setUserId,
};

export default analytics;
