import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import SearchIcon from '@mui/icons-material/Search';

import LogoWhiteImg from '../../assets/logo-white.webp';
import { getMe } from '../../reducers/User/actions';
import { AnalyticsProvider } from '../../providers';

import { LoginStateContext } from './components/LoginDialog/context';
import {
  AccountMenu,
  LoginDialog,
  Notifications,
  OverlaysMenu,
  SignupDialog,
} from './components';
import {
  AppBarStyled,
  Body,
  LinkStyled,
  LogoImage,
  NavBarItem,
  Search,
  SearchIconWrapper,
  StyledInputBase,
  ToolbarStyled,
} from './styles';

function Menu({ children }) {
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isSignupDialogOpen, setIsSignupDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const user = useSelector((state) => state.user);
  const { referral } = user.data;
  const dispatch = useDispatch();
  let history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(getMe());
    }
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const referral = params.get('referral');
    if (referral) {
      localStorage.setItem('referral', referral);
    }
  }, [search]);

  const onClickLogo = () => {
    AnalyticsProvider.event('CLICK_LOGO');
  };
  const onClickSubscription = () => {
    AnalyticsProvider.event('CLICK_SUBSCRIPTION');
    history.push('/subscription');
  };
  const onClickRevenue = () => {
    AnalyticsProvider.event('CLICK_REVENUE');
    history.push('/revenue');
  };
  const onClickLogin = () => {
    AnalyticsProvider.event('CLICK_LOGIN');
    setIsLoginDialogOpen(true);
  };
  const onCloseLoginDialog = () => {
    setIsLoginDialogOpen(false);
  };
  const onClickSignup = () => {
    AnalyticsProvider.event('CLICK_SIGNUP');
    setIsSignupDialogOpen(true);
  };
  const onCloseSignupDialog = () => {
    setIsSignupDialogOpen(false);
  };
  const openLoginDialog = () => {
    setIsLoginDialogOpen(true);
  };
  const onSearch = (event) => {
    event.preventDefault();
    AnalyticsProvider.event('SEARCH_TEMPLATE', { value: searchValue });
    history.push(`/templates/${searchValue}`);
  };

  return (
    <>
      <CssBaseline />
      <AppBarStyled position="fixed">
        <ToolbarStyled>
          <Body>
            <LinkStyled to="/dashboard">
              <LogoImage
                alt="logo"
                onMouseDown={onClickLogo}
                src={LogoWhiteImg}
              />
            </LinkStyled>
            <OverlaysMenu referral={referral} />
            <Box mr={2}>
              <NavBarItem onClick={onClickSubscription} variant="subtitle1">
                {i18n.t('menu.toolbar.subscription')}
              </NavBarItem>
            </Box>
            <NavBarItem onClick={onClickRevenue} variant="subtitle1">
              {i18n.t('menu.toolbar.revenue')}
            </NavBarItem>
          </Body>
          <Hidden mdDown>
            <form noValidate onSubmit={onSearch}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={i18n.t('menu.toolbar.search')}
                  value={searchValue}
                />
              </Search>
            </form>
          </Hidden>
          <Notifications />
          {!user?.data?.id && (
            <>
              <Box mr={1}>
                <Button color="inherit" onClick={onClickLogin}>
                  {i18n.t('menu.toolbar.login')}
                </Button>
              </Box>
              <Button
                color="secondary"
                onClick={onClickSignup}
                variant="contained"
              >
                {i18n.t('menu.toolbar.signup')}
              </Button>
            </>
          )}
          {user?.data?.id && <AccountMenu />}
        </ToolbarStyled>
      </AppBarStyled>
      <Container>
        <ToolbarStyled />
        <LoginStateContext.Provider value={{ openLoginDialog }}>
          {children}
        </LoginStateContext.Provider>
      </Container>
      <LoginDialog
        onClose={onCloseLoginDialog}
        onSignup={onClickSignup}
        open={isLoginDialogOpen}
      />
      <SignupDialog
        onClose={onCloseSignupDialog}
        onLogin={onClickLogin}
        open={isSignupDialogOpen}
      />
    </>
  );
}

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Menu;
